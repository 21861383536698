import React from 'react';
import { inject, observer } from 'mobx-react';
import { VirtualTerminal } from '../components/VirtualTerminal';
import { BiX } from 'react-icons/bi';
import {Helmet} from "react-helmet";

import '../assets/css/bootstrap.min.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/css/custom.css';

@inject('global' , 'vTerminal')
@observer
class ViewVirtualTerminal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            config: {},
            configCustomCss: null,
        }
        this.closeModal = this.closeModal.bind(this);
    }


    closeModal(){
        window.parent.postMessage("close-payabli-modal-vt", '*');
    }

    componentDidMount(){

        let config= JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-mounted-component"+config.randomId,
            data: true
        }, '*');

        if(config.customCssUrl){
            this.setState({
                configCustomCss: config.customCssUrl
            });
        }

    }

    render() {

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        return (
            <div id="main-container">
                <div id="vterminal-container" style={{paddingBottom: "240px"}}>
                    <div className="card-body main-card-body">
                        <VirtualTerminal pconfig={JSON.parse(window.atob(this.props.match.params.config))}/>
                    </div>
                </div>
                <div onClick={(e)=> this.closeModal()} className="closeModalButton"><BiX/></div>
                {this.props.global.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            }
            {(this.state.configCustomCss) &&
            <Helmet>
            <link rel="stylesheet" href={this.state.configCustomCss}/>
            </Helmet>
            }
            </div>
       
        )
    }
}

export { ViewVirtualTerminal };